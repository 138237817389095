/*=== Global jQuery ===*/
jQuery(function ($) {
    'use strict';

    /*=== Direction Detector ====*/
    var pageDirection = $('body').css('direction');

    /*=== wow.js ===*/
    function afterReveal(el){ 
        el.addEventListener('animationend', function(event){
            $(this).addClass("viewActive") 
        });
    };

    new WOW({ callback: afterReveal }).init();

    //==== Multi Langages ====//
    if ( pageDirection == 'rtl' ) {
        //===== RTL Code ====//
        $('.home-slider').slick({
            rtl: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            dots: true,
            arrows:true,
            prevArrow: '<a href="javascript:void(0)" class="slick-prev ti-arrow-left-chevron"></a>',
            nextArrow: '<a href="javascript:void(0)" class="slick-next ti-arrow-right-chevron"></a>',
        });

        /*==== Gallery Slider ====*/
        $('.gallery-slider').slick({
            rtl: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            arrows:true,
            prevArrow: '<a href="javascript:void(0)" class="slick-prev ti-arrow-left-chevron"></a>',
            nextArrow: '<a href="javascript:void(0)" class="slick-next ti-arrow-right-chevron"></a>',
            asNavFor:'.gallery-thumbnails'
        });
        /*==== Gallery Thumbnails ====*/
        $('.gallery-thumbnails').slick({
            rtl: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            arrows:false,
            dots: true,
            slidesToShow:4,
            asNavFor:'.gallery-slider',
            focusOnSelect:true,
        });
    } else {
        //===== LTR Code ====//
        $('.home-slider').slick({
            rtl: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            dots: true,
            arrows:true,
            prevArrow: '<a href="javascript:void(0)" class="slick-prev ti-arrow-left-chevron"></a>',
            nextArrow: '<a href="javascript:void(0)" class="slick-next ti-arrow-right-chevron"></a>',
        });

        /*==== Gallery Slider ====*/
        $('.gallery-slider').slick({
            rtl: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            arrows:true,
            prevArrow: '<a href="javascript:void(0)" class="slick-prev ti-arrow-left-chevron"></a>',
            nextArrow: '<a href="javascript:void(0)" class="slick-next ti-arrow-right-chevron"></a>',
            asNavFor:'.gallery-thumbnails'
        });
        /*==== Gallery Thumbnails ====*/
        $('.gallery-thumbnails').slick({
            rtl: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            arrows:false,
            dots: true,
            slidesToShow:4,
            asNavFor:'.gallery-slider',
            focusOnSelect:true,
        });
    };
});